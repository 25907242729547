/* 私有路由表 */
export const privateRoutes = [
  {
    path: '/',
    name: 'publicShell',
    meta: {
      title: '首页',
      requiresAuth: false,
    },
    // 重定向
    redirect() {
      return '/calculationResult'
    },
    component: () => import('@/components/publicShell.vue'),
    children: [
      // ----------------------------------- 权限管理 -----------------------------------

      // 菜单管理
      {
        path: '/menuManagement',
        name: 'MenuManagement',
        component: () =>
          import('@/views/authorityManagement/menuManagement.vue'),
        meta: {
          title: '权限管理 - 菜单管理',
          requiresAuth: true,
        },
      },
      // 角色管理
      {
        path: '/roleManagement',
        name: 'RoleManagement',
        component: () =>
          import('@/views/authorityManagement/roleManagement.vue'),
        meta: {
          title: '权限管理 - 角色管理',
          requiresAuth: true,
        },
      },
      // 用户管理
      {
        path: '/userManagement',
        name: 'UserManagement',
        component: () =>
          import('@/views/authorityManagement/userManagement.vue'),
        meta: {
          title: '权限管理 - 用户管理',
          requiresAuth: true,
        },
      },

      // ----------------------------------- 油耗管理 -----------------------------------

      // 油耗管理 - 注脂量计算
      {
        path: '/calculationResult',
        name: 'CalculationResult',
        component: () =>
          import('@/views/greaseCalculation/calculationResult.vue'),
        meta: {
          title: '油耗管理 - 注脂量计算',
          requiresAuth: false,
        },
      },

      // 油耗管理 - 轴承类别管理
      {
        path: '/bearingTypeManagement',
        name: 'BearingTypeManagement',
        component: () =>
          import('@/views/greaseCalculation/bearingTypeManagement.vue'),
        meta: {
          title: '油耗管理 - 轴承类别管理',
          requiresAuth: true,
        },
      },

      // ----------------------------------- 系统管理 -----------------------------------

      // 系统管理 - 区域管理
      {
        path: '/regionManagement',
        name: 'RegionManagement',
        component: () =>
          import('@/views/systemManagement/regionManagement.vue'),
        meta: {
          title: '系统管理 - 区域管理',
          requiresAuth: true,
        },
      },
      // 系统管理 - 设备管理
      {
        path: '/deviceManagement',
        name: 'deviceManagement',
        component: () =>
          import('@/views/systemManagement/deviceManagement.vue'),
        meta: {
          title: '系统管理 - 设备管理',
          requiresAuth: true,
        },
      },

      // ----------------------------------- 上传管理 -----------------------------------

      // 上传管理 - 文章管理
      {
        path: '/newsManagement',
        name: 'NewsManagement',
        component: () => import('@/views/newsManagement/newsManagement.vue'),
        meta: {
          title: '上传管理 - 文章管理',
          requiresAuth: true,
        },
      },

      // ----------------------------------- 404页面 -----------------------------------

      // 404页面
      {
        path: '/:catchAll(.*)',
        // redirect: '/404',
        component: () => import('@/views/404.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
]
