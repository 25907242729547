import request from './request'

// 1 - 开发环境;2 - 生产环境
let urlType = 2
if (urlType == 1) {
  var baseURL = '/url' // 基础路径
} else if (urlType == 2) {
  var baseURL = 'https://ltadmin.singllube.com/' // 基础路径
  // var baseURL = window.location.protocol + '//' + window.location.host // 基础路径
}

// ----------------------------------- 用户相关接口 -----------------------------------

const LOGIN = '/back/user/login' // 登录接口

const USERINFO = '/back/user/getUserInfo' // 获取用户信息
const USERMENUS = '/back/user/menus/getUserMenus' // 获取用户菜单

// ----------------------------------- 科学计算 - 注脂计算 -----------------------------------

const BEARINGTYPE = '/back/formula/getEqConfig/bearing' // 设置设备参数 - 获取固定轴承型号

const KCOEFFICIENT = '/back/formula/getEqConfig/getKData' // 设置设备参数 - 获取固定K系数

const CALCULATIONRESULT = '/back/formula/obtainCalculation' // 设置设备参数 - 获得计算

const BEARINGNAME = '/back/formula/getEqConfig/findEquipmentBearingConfigName' // 查找设备轴承配置名称
const BEARINGNAMEFETCHTYPE =
  '/back/formula/getEqConfig/findEquipmentBearingConfigNameData' // 通过名称查询所有轴承配置
const ADDANDUPDATEBEARING =
  '/back/formula/getEqConfig/addAndUpdateBearingModels' // 查找设备轴承配置名称

// ----------------------------------- 科学计算 - 轴承类别管理 -----------------------------------

const ADDBEARINGTYPELIST = '/back/formula/getEqConfig/addBearingModelsList' // 添加轴承型号列表
const DELETEBEARINGTYPE = '/back/formula/getEqConfig/deleteBearingModel' // 删除轴承模型
const DOWNLOADUPLOADMODEL = '/back/formula/getEqConfig/downloadBearingTemplate' // 下载轴承模板

// ----------------------------------- 权限管理 - 菜单管理 -----------------------------------

const menuTreeList = '/back/user/menus/getMenuTree' // 获取菜单树
const ADDMENU = '/back/user/menus/addMenu' // 添加菜单
const UPDATEMENU = '/back/user/menus/updateMenu' // 更新菜单
const DELETEMENU = '/back/user/menus/deleteMenus' // 删除菜单

// ----------------------------------- 权限管理 - 角色管理 -----------------------------------

const ROLELIST = '/back/role/getAllRoles' // 获取角色列表
const ADDROLE = '/back/role/addRole' // 添加角色
const USERROLEPERMISSIONLIST = '/back/role/rolePermissionList' // 角色权限列表
const UPDATEROLE = '/back/role/updateRole' // 更新角色

// ----------------------------------- 权限管理 - 区域/用户管理 -----------------------------------

const REGIONLIST = '/back/user/obtainCustomerList' // 获取区域列表
// const ADDREGIONUSER = '/back/user/addCustUser' // 新增 区域 与 用户
const ADDREGIONUSER = '/back/cust/addCustUser' // 新增 区域 与 用户
const UPDATEREGIONINFO = '/back/user/modifyingRegionalInfo' // 修改区域信息

const ROLEPERMISSIONLIST = '/back/user/getPermissionList' // 获取权限列表
const DEALERLIST = '/back/user/obtainDealerList' // 获取经销商名单

const REGIONUSERLIST = '/back/user/obtainUserListUnderRegion' // 获取区域下用户列表
const ADDUSER = '/back/user/addUser' // 新增 区域 下的 用户
const UPDATEUSERINFO = '/back/user/update' // 更新用户
const RESETUSER = '/back/user/resetCurrentUser' // 重置用户
const FORBIDDENUSER = '/back/user/disableCurrentUser' // 禁用用户
const DELETEUSER = '/back/user/delete' // 删除用户

// ----------------------------------- 系统管理 - 区域管理 -----------------------------------

const MASTERREGIONLIST = '/back/cust/select/mainList' // 获取客户选择主列表
const SUBREGIONLIST = '/back/cust/select/getListRegionsUnderMainRegion' // 获取主区域下列表区域

const ADDMASTERREGIONANDUSER = '/back/cust/addCustUser' // 新增 区域 与 用户
const UPDATEMASTERREGION = '/back/cust/updateCust' // 更新区域
const DELETEMASTERREGION = '/back/cust/deleteCust' // 删除区域

// ----------------------------------- 系统管理 - 设备管理 -----------------------------------

const DEVICEMANAGEMENTLIST = '/back/eq/getDeviceList' // 设备管理列表
const ALLDEVICECONFIGLIST = '/back/eq/deviceConfigurationList' // 设备配置列表
const CLIENTDEVICEGROUPS = '/back/eq/obtainTargetCustomerDeviceGroups' // 获取客户下的分组列表
const REGIONORGROUPDEVICELIST = '/back/eq/getAListGroupedDevices' // 获取 区域 / 分组 设备列表 状态信息
const REGIONORGROUPDEVICECONFIGLIST = '/back/eq/obtainListDevicesInMainArea' // 获取 区域 / 分组 设备列表 配置信息

const DEVICEMODELLIST = '/back/eq/obtainModelList' // 获取型号列表
const ADDDEVICE = '/back/eq/addDevice' // 添加设备

const DEVICECONFIGINFO = '/back/eq/getEquipmentConfig' // 获取设备配置信息
const CONFIG3CALCULATIONRESULT = '/back/eq/obtainCalculationResults' // 获得计算结果
const SUBMITDEVICECONFIG = '/back/eq/updateEqConfig' // 存储配置设备信息

const SNFINDDEVICE = '/back/eq/findDeviceInfoBySN' // 通过sn查找设备信息
const ADDCLIENTDEVICEGROUP = '/back/eq/addDeviceCustomerGroup' // 添加设备客户组

const OILCUPFILL = '/back/eq/sendData' // 发送加满油杯控制指令

class http {
  // 基础配置
  static common({
    url = '',
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
    responseType = '',
    headers = { userMainId: localStorage.getItem('userId') },
  } = {}) {
    return request({
      url,
      //   method是请求方法
      method,
      //   baseURL将于url做字符串拼接合并
      baseURL,
      //   params是get携带的参数
      params,
      //   data是post携带的参数
      data,
      //   设置是否需要Loading
      config,
      // // 设置请求头
      // headers: {
      //   userMainId: localStorage.getItem('userId'),
      //   // 'Content-Type': 'application/x-www-form-urlencoded'
      // },
      // 设置请求头
      headers,
      responseType,
    } as any)
  }

  // ----------------------------------- 用户相关接口 -----------------------------------

  // 登录接口
  static login({ method = 'POST', data = {}, params = {} } = {}) {
    return this.common({
      url: LOGIN,
      method,
      params,
      data,
    })
  }

  // 获取用户菜单
  static getUserInfo({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: USERINFO,
      method,
      params,
      data,
      config,
    })
  }

  // 获取用户菜单
  static getUserMenus({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: USERMENUS,
      method,
      params,
      data,
      config,
    })
  }

  // ----------------------------------- 权限管理 - 菜单管理 -----------------------------------

  // 获取用户菜单
  static getMenuTree({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: menuTreeList,
      method,
      params,
      data,
      config,
    })
  }
  // 添加菜单
  static submitAddMenu({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDMENU,
      method,
      params,
      data,
      config,
    })
  }
  // 更新菜单
  static submitUpdateMenu({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: UPDATEMENU,
      method,
      params,
      data,
      config,
    })
  }
  // 删除菜单
  static submitDeleteMenu({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DELETEMENU,
      method,
      params,
      data,
      config,
    })
  }

  // ----------------------------------- 权限管理 - 角色管理 -----------------------------------

  // 获取角色列表
  static getRoleList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ROLELIST,
      method,
      params,
      data,
      config,
    })
  }
  // 添加角色
  static submitAddRole({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDROLE,
      method,
      params,
      data,
      config,
    })
  }
  // 角色权限列表
  static getUserRolePermissionList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: USERROLEPERMISSIONLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 更新角色
  static submitUpdateRole({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: UPDATEROLE,
      method,
      params,
      data,
      config,
    })
  }

  // ----------------------------------- 权限管理 - 区域/用户管理 -----------------------------------

  // 获取区域列表
  static getRegionList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: REGIONLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 新增 区域 与 用户
  static addRegionUser({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDREGIONUSER,
      method,
      params,
      data,
      config,
    })
  }
  // 修改区域信息
  static updateRegionInfo({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: UPDATEREGIONINFO,
      method,
      params,
      data,
      config,
    })
  }

  // 获取权限列表
  static getRolePermissionList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ROLEPERMISSIONLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 获取经销商名单
  static getDealerList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DEALERLIST,
      method,
      params,
      data,
      config,
    })
  }

  // 获取区域下用户列表
  static getRegionUserList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: REGIONUSERLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 新增 区域 下的 用户
  static addUser({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDUSER,
      method,
      params,
      data,
      config,
    })
  }
  // 更新用户
  static updateUserInfo({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: UPDATEUSERINFO,
      method,
      params,
      data,
      config,
    })
  }
  // 重置用户
  static resetUser({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: RESETUSER,
      method,
      params,
      data,
      config,
    })
  }
  // 禁用用户
  static forbiddenUser({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: FORBIDDENUSER,
      method,
      params,
      data,
      config,
    })
  }
  // 删除用户
  static deleteUser({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DELETEUSER,
      method,
      params,
      data,
      config,
    })
  }

  // ----------------------------------- 科学计算 - 注脂计算 -----------------------------------

  // 设置设备参数 - 获取固定轴承型号
  static getBearingTypeList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: BEARINGTYPE,
      method,
      params,
      data,
      config,
    })
  }

  // 设置设备参数 - 获取固定K系数
  static getKCoefficientList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: KCOEFFICIENT,
      method,
      params,
      data,
      config,
    })
  }

  // 设置设备参数 - 获取固定K系数
  static getCalculationResult({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: CALCULATIONRESULT,
      method,
      params,
      data,
      config,
    })
  }

  // 查找设备轴承配置名称
  static getBearingName({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: BEARINGNAME,
      method,
      params,
      data,
      config,
    })
  }

  // 查找设备轴承配置名称
  static getBearingNameFetchType({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: BEARINGNAMEFETCHTYPE,
      method,
      params,
      data,
      config,
    })
  }

  // 查找设备轴承配置名称
  static addAndUpdateBearing({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDANDUPDATEBEARING,
      method,
      params,
      data,
      config,
    })
  }

  // ----------------------------------- 科学计算 - 轴承类别管理 -----------------------------------

  // 添加轴承型号列表
  static addBearingTypeList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDBEARINGTYPELIST,
      method,
      params,
      data,
      config,
    })
  }
  // 删除轴承模型
  static deleteBearingType({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DELETEBEARINGTYPE,
      method,
      params,
      data,
      config,
    })
  }
  // 下载轴承模板
  static downLoadUploadModel({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
    responseType = 'blob',
    headers = {
      userMainId: localStorage.getItem('userId'),
      'Content-Type': 'application/vnd.ms-excel',
    },
  } = {}) {
    return this.common({
      url: DOWNLOADUPLOADMODEL,
      method,
      params,
      data,
      config,
      responseType,
      headers,
    })
  }

  // ----------------------------------- 系统管理 - 区域管理 -----------------------------------

  // 获取客户选择主列表
  static getMasterRegionList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: MASTERREGIONLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 获取主区域下列表区域
  static getSubRegionList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: SUBREGIONLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 新增 区域 与 用户
  static addMasterRegionAndUser({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDMASTERREGIONANDUSER,
      method,
      params,
      data,
      config,
    })
  }
  // 更新区域
  static updateMasterRegion({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: UPDATEMASTERREGION,
      method,
      params,
      data,
      config,
    })
  }
  // 删除区域
  static deleteMasterRegion({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DELETEMASTERREGION,
      method,
      params,
      data,
      config,
    })
  }

  // ----------------------------------- 系统管理 - 设备管理 -----------------------------------

  // 设备管理列表
  static getDeviceManagementList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DEVICEMANAGEMENTLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 设备配置列表
  static getAllDeviceConfigList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ALLDEVICECONFIGLIST,
      method,
      params,
      data,
      config,
    })
  }

  // 获取客户下的分组列表
  static getClientDeviceGroups({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: CLIENTDEVICEGROUPS,
      method,
      params,
      data,
      config,
    })
  }
  // 获取 区域 / 分组 设备列表状态信息
  static getRegionOrGroupDeviceList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: REGIONORGROUPDEVICELIST,
      method,
      params,
      data,
      config,
    })
  }
  // 获取 区域 / 分组 设备列表状态信息
  static getRegionOrGroupDeviceConfigList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: REGIONORGROUPDEVICECONFIGLIST,
      method,
      params,
      data,
      config,
    })
  }

  // 获取型号列表
  static getDeviceModelList({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DEVICEMODELLIST,
      method,
      params,
      data,
      config,
    })
  }
  // 添加设备
  static submitAddDevice({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDDEVICE,
      method,
      params,
      data,
      config,
    })
  }

  // 获取设备配置信息
  static getDeviceConfigInfo({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: DEVICECONFIGINFO,
      method,
      params,
      data,
      config,
    })
  }
  // 获得计算结果
  static getConfig3CalculationResult({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: CONFIG3CALCULATIONRESULT,
      method,
      params,
      data,
      config,
    })
  }

  // 存储配置设备信息
  static submitDeviceConfig({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: SUBMITDEVICECONFIG,
      method,
      params,
      data,
      config,
    })
  }

  // 通过sn查找设备信息
  static useSnFindDevice({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: SNFINDDEVICE,
      method,
      params,
      data,
      config,
    })
  }

  // 添加设备客户组
  static addClientDeviceGroup({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: ADDCLIENTDEVICEGROUP,
      method,
      params,
      data,
      config,
    })
  }

  // 发送加满油杯控制指令
  static sendOilCupFill({
    method = 'POST',
    data = {},
    params = {},
    config = { showLoading: true },
  } = {}) {
    return this.common({
      url: OILCUPFILL,
      method,
      params,
      data,
      config,
    })
  }

  // 导出baseUrl
  static getBaseUrl() {
    return baseURL
  }
}

export default http
